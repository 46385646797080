import React, { Component } from 'react';
import { TNWrapper, AlbumCover, AlbumLink, LinkContainer } from './tnw.css';

import { FollowLinks, FLink } from '../header/header.css';
import Cover from '../../../static/cover.jpg';

class TNW extends Component {
  render() {
    const { country_name } = this.props.country; // eslint-disable-line
    return (
      <TNWrapper>
        <AlbumCover src={Cover} />

        <LinkContainer>
          <AlbumLink
            id="spotify_DSP_BTN"
            href="https://lauv.lnk.to/lonelylp/spotify"
          >
            Listen on Spotify
          </AlbumLink>
          <AlbumLink
            id="applemusic_DSP_BTN"
            href="https://lauv.lnk.to/lonelylp/applemusic"
          >
            Listen on Apple Music
          </AlbumLink>
          <AlbumLink
            id="amazonmusic_DSP_BTN"
            href="https://lauv.lnk.to/lonelylp/amazonmusic"
          >
            Listen on Amazon
          </AlbumLink>
          <AlbumLink
            id="itunes_DSP_BTN"
            href="https://lauv.lnk.to/lonelylp/itunes"
          >
            Buy on iTunes
          </AlbumLink>
          {country_name === 'India' ? (
            <AlbumLink
              id="jiosaavn_DSP_BTN"
              href="https://www.jiosaavn.com/song/drugs--the-internet/JFAIVRlVYgY"
            >
              Listen on JioSaavn
            </AlbumLink>
          ) : (
            <AlbumLink
              id="deezer_DSP_BTN"
              href="https://lauv.lnk.to/lonelylp/deezer"
            >
              Listen on Deezer
            </AlbumLink>
          )}
        </LinkContainer>

        <FollowLinks bottom>
          <FLink
            bottom
            href="https://lauv.lnk.to/spotifysubscribeil"
            id="spotify_Social_BTN"
          >
            Follow On Spotify
          </FLink>
          <FLink
            bottom
            href="https://lauv.lnk.to/appleil"
            id="apple_Social_BTN"
          >
            Follow On Apple Music
          </FLink>
        </FollowLinks>
      </TNWrapper>
    );
  }
}

export default TNW;
