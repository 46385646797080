import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SVGLink = styled.a.attrs({
  target: '_blank',
})`
  transform: scale(0.8);
  display: block;
  svg path {
    fill: '#eb7f2b';
    transition: 180ms ease-out;
  }
  &:hover {
    svg path {
      fill: #000;
    }
  }
`;

const default_color = "#eb7f2b";

class SocialLink extends Component {
  static propTypes = {
    url: PropTypes.string,
    color: PropTypes.string,
  };

  static Youtube = ({ url, id, color = default_color }) => (
    <SVGLink href={url} color={color} id={id}>
      <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="24"
        height="18"
        viewBox="0 0 24 18"
      >
        <path
          fill={color}
          id="youtube-a"
          d="M23.3565538,3.20286621 C23.083941,2.16355957 22.2807292,1.34503418 21.2608941,1.06725586 C19.4123698,0.5625 12,0.5625 12,0.5625 C12,0.5625 4.58767361,0.5625 2.7391059,1.06725586 C1.71927083,1.34507813 0.916059028,2.16355957 0.643446181,3.20286621 C0.148133681,5.08666992 0.148133681,9.01705078 0.148133681,9.01705078 C0.148133681,9.01705078 0.148133681,12.9474316 0.643446181,14.8312354 C0.916059028,15.870542 1.71927083,16.6549658 2.7391059,16.9327441 C4.58767361,17.4375 12,17.4375 12,17.4375 C12,17.4375 19.4123264,17.4375 21.2608941,16.9327441 C22.2807292,16.6549658 23.083941,15.870542 23.3565538,14.8312354 C23.8518663,12.9474316 23.8518663,9.01705078 23.8518663,9.01705078 C23.8518663,9.01705078 23.8518663,5.08666992 23.3565538,3.20286621 Z M9.57573785,12.585542 L9.57573785,5.44855957 L15.7710069,9.01713867 L9.57573785,12.585542 Z"
        />
      </svg>
    </SVGLink>
  );

  static Twitter = ({ url, id, color = default_color }) => (
    <SVGLink href={url} color={color} id={id}>
      <svg width="28" height="22" viewBox="0 0 28 22">
        <path
          fill={color}
          d="M24.3290113,5.48279254 C24.3462238,5.72340605 24.3462238,5.96407246 24.3462238,6.20468597 C24.3462238,13.5437154 18.7543698,22 8.53408775,22 C5.38540939,22 2.46044121,21.0890229 0,19.5078408 C0.447367391,19.5593706 0.877469271,19.5765649 1.34204921,19.5765649 C3.94008489,19.5765649 6.33172886,18.7000291 8.24158034,17.2047124 C5.79835168,17.1531297 3.75085277,15.5547005 3.04540307,13.3546846 C3.38954812,13.4062145 3.73364022,13.4406029 4.09499783,13.4406029 C4.5939499,13.4406029 5.09295494,13.371826 5.55748192,13.2515721 C3.01103093,12.7359037 1.10112649,10.5015523 1.10112649,7.80311516 L1.10112649,7.73439112 C1.84094832,8.1468941 2.70131097,8.40470184 3.61315238,8.43903741 C2.11624319,7.44214199 1.13555158,5.74060028 1.13555158,3.8155864 C1.13555158,2.78435541 1.41079348,1.8390427 1.89258597,1.01403675 C4.62832204,4.3827846 8.74053242,6.58274757 13.3516419,6.82341398 C13.2656321,6.41091101 13.2139945,5.9812667 13.2139945,5.55156949 C13.2139945,2.49215919 15.6916482,0 18.7714764,0 C20.3716079,0 21.8168795,0.670310723 22.8320491,1.75312442 C24.0880356,1.51251091 25.2924374,1.04842522 26.3592447,0.412502976 C25.9462494,1.70159462 25.0687802,2.78440831 23.916016,3.47186037 C25.034408,3.35160652 26.1183749,3.04216316 27.1162791,2.61251885 C26.3593506,3.71247388 25.4129782,4.69212215 24.3290113,5.48279254 Z"
        />
      </svg>
    </SVGLink>
  );

  static Instagram = ({ url, id, color = default_color }) => (
    <SVGLink href={url} id={id}>
      <svg width="23" height="22">
        <path
          fill={color}
          d="M11.03 5.39a5.62 5.62 0 0 0-5.63 5.63 5.62 5.62 0 0 0 5.63 5.63 5.62 5.62 0 0 0 5.63-5.63 5.62 5.62 0 0 0-5.63-5.63zm0 9.29a3.667 3.667 0 0 1-3.66-3.66 3.664 3.664 0 0 1 3.66-3.66 3.664 3.664 0 0 1 3.66 3.66 3.667 3.667 0 0 1-3.66 3.66zm7.173-9.52a1.31 1.31 0 0 1-1.313 1.313 1.313 1.313 0 1 1 1.313-1.313zm3.728 1.332c-.083-1.759-.485-3.317-1.773-4.6C18.874.607 17.316.205 15.557.117c-1.813-.103-7.247-.103-9.06 0-1.754.083-3.312.485-4.6 1.768C.606 3.17.21 4.728.121 6.487.02 8.3.02 13.734.122 15.547c.084 1.759.486 3.317 1.774 4.6 1.289 1.285 2.842 1.686 4.601 1.775 1.813.102 7.247.102 9.06 0 1.759-.084 3.317-.485 4.6-1.774 1.284-1.284 1.686-2.842 1.774-4.601.103-1.813.103-7.242 0-9.055zm-2.342 11a3.706 3.706 0 0 1-2.087 2.088c-1.445.573-4.875.44-6.473.44-1.597 0-5.032.128-6.472-.44a3.706 3.706 0 0 1-2.088-2.088c-.573-1.445-.44-4.875-.44-6.472 0-1.598-.128-5.032.44-6.473A3.706 3.706 0 0 1 4.557 2.46c1.445-.574 4.875-.441 6.472-.441 1.598 0 5.032-.128 6.473.44.96.383 1.7 1.123 2.087 2.088.574 1.445.441 4.875.441 6.473 0 1.597.133 5.032-.44 6.472z"
        />
      </svg>
    </SVGLink>
  );

  static Facebook = ({ url, id, color = default_color }) => (
    <SVGLink href={url} id={id}>
      <svg width="22" height="22">
        <path
          fill={color}
          d="M22 1.213v19.57c0 .672-.545 1.212-1.213 1.212H15.18V13.48h2.858l.427-3.32h-3.29V8.04c0-.963.265-1.616 1.645-1.616h1.758v-2.97a23.636 23.636 0 0 0-2.563-.133c-2.534 0-4.272 1.547-4.272 4.39v2.45H8.874v3.32h2.868V22H1.212A1.216 1.216 0 0 1 0 20.787V1.213C0 .545.545 0 1.213 0h19.57C21.454 0 22 .545 22 1.213z"
        />
      </svg>
    </SVGLink>
  );

  static Spotify = ({ url, id, color = default_color }) => (
    <SVGLink href={url} id={id}>
      <svg width="22" height="22" viewBox="0 0 22 22">
        <path
          fill={color}
          d="M11,0 C4.92782258,0 0,4.92782258 0,11 C0,17.0721774 4.92782258,22 11,22 C17.0721774,22 22,17.0721774 22,11 C22,4.92782258 17.0721774,0 11,0 Z M15.4665323,16.1850806 C15.2802419,16.1850806 15.1649194,16.1274194 14.9919355,16.0254032 C12.2241935,14.3576613 9.00403226,14.2866935 5.82379032,14.9387097 C5.65080645,14.9830645 5.42459677,15.0540323 5.29596774,15.0540323 C4.86572581,15.0540323 4.59516129,14.7125 4.59516129,14.3532258 C4.59516129,13.896371 4.86572581,13.6790323 5.1983871,13.6080645 C8.83104839,12.8052419 12.5435484,12.8762097 15.7104839,14.7701613 C15.9810484,14.9431452 16.1407258,15.0983871 16.1407258,15.5020161 C16.1407258,15.9056452 15.8258065,16.1850806 15.4665323,16.1850806 Z M16.6596774,13.2754032 C16.4290323,13.2754032 16.2737903,13.1733871 16.1141129,13.0891129 C13.3419355,11.4479839 9.20806452,10.7870968 5.53104839,11.7850806 C5.31814516,11.8427419 5.20282258,11.9004032 5.00322581,11.9004032 C4.52862903,11.9004032 4.14274194,11.5145161 4.14274194,11.0399194 C4.14274194,10.5653226 4.3733871,10.2504032 4.83024194,10.1217742 C6.06330645,9.77580645 7.32298387,9.51854839 9.16814516,9.51854839 C12.0467742,9.51854839 14.8278226,10.2326613 17.0189516,11.5366935 C17.3782258,11.7495968 17.5201613,12.0245968 17.5201613,12.4104839 C17.5157258,12.8895161 17.1431452,13.2754032 16.6596774,13.2754032 Z M18.0346774,9.89556452 C17.8040323,9.89556452 17.6620968,9.83790323 17.4625,9.72258065 C14.3044355,7.8375 8.65806452,7.38508065 5.00322581,8.40524194 C4.84354839,8.44959677 4.64395161,8.52056452 4.43104839,8.52056452 C3.84556452,8.52056452 3.39758065,8.06370968 3.39758065,7.47379032 C3.39758065,6.87056452 3.77016129,6.52903226 4.16935484,6.41370968 C5.73064516,5.95685484 7.47822581,5.73951613 9.38104839,5.73951613 C12.6189516,5.73951613 16.0120968,6.41370968 18.4915323,7.85967742 C18.8375,8.05927419 19.0637097,8.33427419 19.0637097,8.86209677 C19.0637097,9.46532258 18.5758065,9.89556452 18.0346774,9.89556452 Z"
        />
      </svg>
    </SVGLink>
  );

  static Itunes = ({ url, id, color = default_color }) => (
    <SVGLink href={url} id={id}>
      <svg width="19" height="22" viewBox="0 0 19 22">
        <path
          fill={color}
          d="M18.5567242,17.3149773 C18.2457604,18.6470348 17.2351283,19.3957095 15.8795208,19.6485088 C14.6891128,19.8672773 13.697916,19.9207541 12.7455895,19.1526333 C11.5843344,18.175467 11.569758,16.5565795 12.6144017,15.5356595 C13.4403991,14.7480926 14.6016543,14.5876624 16.3459664,14.2813863 C16.6374949,14.2279096 16.890153,14.1598482 17.1039405,13.9216336 C17.4149042,13.5716039 17.3177281,13.7223111 17.3177281,5.98762653 C17.3177281,5.44313585 17.0504936,5.29242861 16.4917307,5.38965909 C16.0933084,5.45772042 7.46892382,7.07174637 7.46892382,7.07174637 C6.97332539,7.17869989 6.81784352,7.32454561 6.81784352,7.88362086 C6.81784352,19.2936175 6.87129041,18.7685729 6.69637332,19.5269707 C6.49230337,20.411768 5.94811685,21.0777968 5.22901324,21.4472726 C4.41273346,21.8993943 2.93565576,22.0987168 2.14852883,21.9528711 C0.0495236775,21.5590877 -0.689015171,19.1331872 0.734615636,17.7427914 C1.56061303,16.9552245 2.7218682,16.7947942 4.46618035,16.4885182 C4.75770884,16.4350414 5.01036687,16.3669801 5.22415443,16.1287654 C5.71489406,15.5696902 5.31161298,3.65409505 5.47681246,2.99292779 C5.51568292,2.74012855 5.6225767,2.52622149 5.82178784,2.3657912 C6.02585778,2.19563787 6.39512721,2.09840739 6.47286814,2.09840739 C16.3848369,0.241305249 17.5946801,0.00309057635 17.7647384,0.00309057635 C18.3235014,-0.0358016151 18.6441827,0.294782012 18.6441827,0.858718787 C18.6539003,17.6066687 18.6976296,16.7072868 18.5567242,17.3149773 Z"
        />
      </svg>
    </SVGLink>
  );

  static GooglePlay = ({ url, id, color = default_color }) => (
    <SVGLink href={url} id={id}>
      <svg width="20" height="22" viewBox="0 0 20 22">
        <path
          fill={color}
          d="M12.872366,10.0695448 L3.40259541,0.558702872 L15.45113,7.48661848 L12.872366,10.0695448 Z M0.931101139,0 C0.373298613,0.292244579 0,0.825161164 0,1.51709318 L0,20.4829068 C0,21.1748388 0.373298613,21.7077554 0.931101139,22 L11.9412648,10.9978511 L0.931101139,0 Z M19.1755345,9.69564368 L16.64826,8.23012307 L13.8292118,11.0021489 L16.64826,13.7741746 L19.227024,12.308654 C19.9993659,11.6940809 19.9993659,10.3102168 19.1755345,9.69564368 Z M3.40259541,21.4455948 L15.45113,14.5176792 L12.872366,11.9347529 L3.40259541,21.4455948 Z"
        />
      </svg>
    </SVGLink>
  );
}

export default SocialLink;
